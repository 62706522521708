import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {

  apiKey: "AIzaSyClm0oZ2EWl2ry-2wnPma4LGOOMydNLlgM",

  authDomain: "orioff.firebaseapp.com",

  projectId: "orioff",

  storageBucket: "orioff.firebasestorage.app",

  messagingSenderId: "421644244717",

  appId: "1:421644244717:web:1d326c0487d73faf8fdee4",

  measurementId: "G-P579LEY1CW"

};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };