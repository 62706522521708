import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase-config";
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import googleIcon from '../img/googleIcon.png';

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      navigate("/account");

    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      navigate("/account");
  
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className='form' id="signUpForm">
      <h1>Create account</h1>
      <p style={{color: "orange", fontSize: "18px", fontWeight: "600"}}>Sign in is disabled while this page is used as my portfolio.</p>
      <form onSubmit={handleSubmit}>
        <label htmlFor='email' className='formLabel'>
          <span>Email</span>
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        </label>
        <input
          type="email"
          value={email}
          className='formInput'
          id="email"
          onChange={(e) => setEmail(e.target.value)}
          disabled
        />
        <label htmlFor='password' className='formLabel'>
          <span>Password</span>
          <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        </label>
        <input
          type="password"
          className='formInput'
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled
        />
        <div style={{display:"flex", flexDirection:"column"}}>
          <button disabled style={{  margin: "auto", marginBottom: "40px"}} className='portadabutton' id='formButtonPopup' type="submit">Sign up</button>

          <div style={{backgroundColor: "whitesmoke", height: "1px", marginBottom: "40px"}}></div>

          <button disabled style={{ display: "flex", margin: "auto", alignItems: "center", gap: "7px"}} className='portadabutton' id='formButtonPopup' onClick={handleGoogleSignIn}><img src={googleIcon} style={{width: "36px", height: "36px"}} alt="Google icon"></img>Sign in with Google</button>
        </div>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default SignUp;