import logo from './logo.svg';
import 'typeface-montserrat';
import './index.css';
import './App.css';
import item1 from './img/item1.jpg';
import item2 from './img/item2.jpeg';
import item3 from './img/item3.jpeg';
import User from "./classes/user.js";
import { useState } from 'react';
import Popup from "./components/Popup";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SignUp from './components/SignUp.js';

// Crear un nuevo usuario
// const newUser = new User("unique-user-id", "user@example.com", "Juan Pérez", 30, "Argentina");

const scrollToSection = (sectionid) => {
  const section = document.getElementById(sectionid);
  section.scrollIntoView({ behavior: 'smooth' });
}

function App() {
  const [isLogged, setIsLogged] = useState(true); // Debería ser false de primeras
  const [isOpen, setIsOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const togglePopupInfo = () => {
    setIsOpen(!isOpen);
  };  

  const togglePopupSignIn = () => {
    setLogin(false);
    setIsOpen(!isOpen);
  }; 

  return (
    <div className="App">
      <header className="App-header">
        <div id='ownnamediv' style={{gap: '1.5vw'}}>
          <p id='ownname'>Fabio Senande Torrado</p><p>✦</p>
          <button id='login' className='portadabutton' onClick={togglePopupSignIn}>Sign In</button>
        </div>
         <div id='orioffdiv'>
          <div>
            <p className='orioffstudio' id='orioff'>OriOFF</p>
            <p className='orioffstudio' id='studio'>Studio</p>
          </div>
          <div id='portadabuttons'>
            <button className='portadabutton' id='buynow' onClick={() => scrollToSection('items-section')}>My Apps</button>
            <button className='portadabutton' >About Me</button>
          </div>
        </div>       
      </header>
      <section className="section" id='items-section'>
        <div id='titleapps'>
          <p>Applications</p>
        </div>
        <div id='items'>
          <button className='item' onClick={() => window.location.href = 'https://capeer.orioffstudio.com'} aria-label="Go to Concert Search App section">
            <img className='itemimage' alt="Concert Search Icon" src={item1}></img>
            <h3 className='itemtitle'>Concert Search App</h3>
            <p className='itemdesc'>Search nearby concerts of your favorite artists</p>
          </button>
          <button className='item' onClick={() => scrollToSection('section2')}>
            <img className='itemimage' src={item2}></img>
            <h3 className='itemtitle'>Photo Competition App</h3>
            <p className='itemdesc'>Compete with your friends and showcase your photos</p>
          </button>
          <button className='item' onClick={() => scrollToSection('section3')}>
            <img className='itemimage' src={item3}></img>
            <h3 className='itemtitle'>Project Management App</h3>
            <p className='itemdesc'>Manage your organizations, projects and reviews</p>
          </button>
        </div>
        <div id='sectionbutton'>
          <button className='portadabutton' id='shopall'>About OriOFF</button>
        </div>
      </section>
      <section className="section" id='section1'>
        <div id='sectiontext'>
          <h3 className='sectiontitle'>Concert Search App</h3>
          <p className='sectiondesc'>Link your Spotify account and search nearby concerts of your favorite artists and playlists. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <img className='sectionimage' src={item1}></img>
      </section>
      <section className="section" id='section2'>
        <img className='sectionimagerev' src={item2}></img>
        <div id='sectiontext'>
          <h3 className='sectiontitle'>Photo Competition App</h3>
          <p className='sectiondesc'>Compete with your friends and showcase your photos. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
      </section>
      <section className="section" id='section3'>
        <div id='sectiontext'>
          <h3 className='sectiontitle'>Project Management App</h3>
          <p className='sectiondesc'>Manage your organizations, projects and reviews. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
        </div>
        <img className='sectionimage' src={item3}></img>
      </section>
      <footer className='footer'>
        <p>Projects by Fabio Senande Torrado</p>
        <p>fst2121@gmail.com</p>
      </footer>
      {login ? 
        <Popup isOpen={isOpen} onClose={togglePopupInfo} >
          <h3 style={{maxWidth: "60vw", color: "#ffb547"}}>
            Aquí va solo lo de FireBase, a lo mejor ni Popup le meto.
          </h3>

        </Popup> 
        
        : 
        
        <Popup isOpen={isOpen} onClose={togglePopupSignIn} >
          <SignUp />
        </Popup>
      }
      
    </div>
  );
}

export default App;
