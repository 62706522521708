import React from 'react';
import '../css/popup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Popup = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
      <div className="popup-overlay" onClick={onClose}>
          <div className="popup-content" onClick={e => e.stopPropagation()}>
              <button className="close-button" onClick={onClose}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
              {children}
          </div>
      </div>
  );
};

export default Popup;